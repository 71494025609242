@media (max-width: 767px) {
  /* Helper Classes Starts Here */
  .heading {
    text-align: center;
    font-size: 1.5rem !important;
  }

  .square-paragraph{
    font-size: .9rem;
    text-align: left;
  }

  .line {
    display: none;
  }

  .card-line {
    width: 30%;
  }

  .display-none {
    display: none;
  }

  .overlay {
    padding: 2rem 0;
  }

  /* Helper Classes Ends Here */

  /* Home Section Styles Starts Here */
  .home-img {
    width: 100%;
    height: 100%;
  }

  /* Home Section Styles Ends Here */

  /* About Section Starts Here */

  .about-container h1 {
    text-align: center !important;
    font-size: 2rem;
  }
  .about-container p {
    text-align: center !important;
  }

  /* About Section Ends Here */

  /* Client Section Starts Here */

  .client-image img {
    width: 3.5rem;
  }

  /* Client Section Ends Here */

  /* Service Section Starts Here */

  .gradient-card {
    height: 25rem;
  }

  .gradient-card h1 {
    font-size: 2rem;
  }
  .card-content {
    width: 100%;
  }
  .service-section-container h1 {
    font-size: 2rem;
  }

  /* Service Section Ends Here */

  /* Project Section Starts Here */

  .project-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .gap-slider {
    margin-left: 1rem;
  }
  /* Project Section Ends Here */

  /* Call Section Styles Starts Here */

  .call-container {
    text-align: center;
  }

  .bg-image {
    height: 50vh;
    background-position: right;
  }

  /* Call Section Styles Ends Here */

  /* Contact Section Styles Starts Here */

  .contact-container {
    text-align: center;
  }

  .call-container h1 {
    font-size: 2rem;
  }

  .contact-ico img {
    width: 1.2rem;
    margin-right: .2rem;
  }

  .contact-ico {
    text-align: start;
    align-items: center;
  }

  .contact-form button {
    width: 100%;
  }

  /* Contact Section Styles Ends Here */

  /* Blog Section Styles Starts Here */
  .blog-container {
    padding: 2rem 0 0 0;
  }

  .blog-content {
    padding: 0 !important;
  }

  .slider-controls {
    padding-bottom: 4rem;
  }

  .slider-controls button {
    position: relative;
    top: 2rem;
    z-index: 5;
  }

  .slider-controls button img {
    width: 1rem;
  }
  /* Blog Section Styles Ends Here */

  /* Footer Section Start Here */
  .footer-container {
    text-align: center;
  }
  .footer-brand {
    width: 50%;
    position: relative;
    bottom: 0%;
    left: 0%;
  }
  .footer-brand-info {
    position: relative;
    bottom: 15%;
  }

  /* Footer Section Ends Here */
}

/* Tablet Responsive Starts Here */

@media (min-width: 768px) and (max-width: 1024px) {
  /* Helper Class Styles Starts Here */
  .display-none {
    display: none;
  }
  .line {
    width: 15% !important;
    height: 0.8rem;
    float: right !important;
    margin: 0 auto !important;
  }
  .bg-image {
    height: 50vh;
    background-position: right;
  }
  /* Helper Class Styles Ends Here */

  /* About Section Style Starts Here */
  .about-container h1 {
    text-align: right !important;
    font-size: 2rem;
  }
  .about-container p {
    text-align: justify !important;
  }

  /* About Section Style Ends Here */

  /* Call Section Styles Starts Here */

  .call-container {
    text-align: center;
  }

  /* Call Section Styles Ends Here */

  /* Blog Section Styles Starts Here */

  .blog-content h1 {
    font-size: 1.1rem;
    margin: 1rem 0 0 0;
  }
  /* Blog Section Styles Ends Here */

  /* Footer Section Start Here */
  .footer-container {
    text-align: start;
  }

  .footer-container p {
    font-size: 0.9rem;
  }

  .footer-container h1 {
    font-size: 1.5rem;
  }
  .footer-brand {
    width: 50%;
    position: relative;
    bottom: 10%;
    left: 0%;
  }
  .footer-brand-info {
    position: relative;
    bottom: 15%;
  }

  /* Footer Section Ends Here */
}

/* Tablet Responsive Ends Here */
