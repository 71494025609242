/* Navbar Styles Starts Here */
.navbar-container {
  padding: 0.5rem 0;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffffe0;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.hamburger-menu-button {
  display: block;
  background: none;
  border: none;
  font-size: 24px;
  color: #000;
  cursor: pointer;
}

.menubar-icon svg{
  font-size: 2rem;
  color: var(--primary);
   display: none;
}

.logo-img{
  width: 5rem;
}

/* Mobile menu styles */
.nav-links.open,
.social-item.open {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #333;
}


.nav-links p {
  text-transform: capitalize;
  margin: 0 1rem;
  font-weight: 500;
  cursor: pointer;
}

.social-item a {
  padding: 0 0.2rem;
}

.social-item a :hover {
  scale: 1.2;
}
.active-link {
  color: var(--primary);
  font-weight: bold;
  scale: 1.1;
}

.social-item img {
  width: 2rem;
}

/* Navbar Styles Ends Here */

/* Media query for mobile screens Starts Here*/
@media (max-width: 770px) {
  .nav-links,
  .social-item {
    display: none !important;
  }
  .logo-img{
    position: relative;
    left: -1rem;
  }

  .cross-icon{
    position: relative;
    right: -1rem;
  }
  .menu-dropdown {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fffffff6;
    backdrop-filter: blur(20px);
    z-index: 999;
    text-align: center;
    overflow-y: hidden;
  }

  .menubar-icon{
    display: flex;
    justify-content: flex-end;
  }

  .menubar-icon svg{
     display: block;
  }
  .menu-content {
    color: var(--black);
  }

  .menu-content p {
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;
    text-transform: capitalize;
    color: var(--primary);
  }
  .nav-social {
    width: 3.5rem;
  }

  .menu-links {
    margin-top: 1rem;
  }

  .img-container-nav {
    display: flex;
    justify-content: space-between;
  }
}
/* Media query for mobile screens Ends Here*/
