/* Service Page Styles Starts Here */
.service-page {
  background: linear-gradient(
    100deg,
    #ffffff,
    #17495615,
    #ffffff,
    #ffffff,
    #ffffff
  );
}
.seo-page {
  background: linear-gradient(
    150deg,
    #ffffff,
    #17495615,
    #ffffff,
    #ffffff,
    #ffffff
  );
}

/* Inherit Value Change */
.page-card .service-card {
  max-width: 100%;
}

/* Tab Component Styles Starts Here */
.tab-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}
.tab-buttons {
  font-size: 1rem;
  color: var(--primary);
  font-weight: 500;
}

.tab-buttons div {
  margin: 2rem 0;
  cursor: pointer;
}

.tab-content {
  width: 45%;
  margin: 2rem 0;
  color: #174956de;
}

.tab-buttons .active-tab {
  border-bottom: 1px solid var(--primary);
  padding-bottom: 9px;
  cursor: pointer;
  scale: 1.1;
}
/* Tab Component Styles Ends Here */

/* Steps Card Component Styling Starts Here */

.step-card {
  display: flex;
  gap: 1.5rem;
}

.ani:hover {
  transition: 0.2s ease;
  cursor: pointer;
  -webkit-box-shadow: 0px 37px 76px -26px rgba(23, 73, 86, 1);
  -moz-box-shadow: 0px 37px 76px -26px rgba(23, 73, 86, 1);
  box-shadow: 0px 37px 76px -26px rgba(23, 73, 86, 1);
}

.step-icon svg {
  font-size: 2.5rem;
  color: var(--secondary);
}

.step-content span {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
}

.step-content p {
  font-size: 0.9rem;
  line-height: 1.6rem;
}

/* Steps Card Component Styling Ends Here */

/* Method Component Styling Starts Here */
.m-c {
  padding: 0 1rem;
  margin: 1rem 0;
  height: 100%;
}

.m-c h1 {
  color: var(--primary);
  font-size: 1.5rem;
  margin: 1rem;
  border: 2px solid var(--primary);
  display: inline-block;
  padding: 1.3rem 2rem;
  border-radius: 100%;
}

.m-c span {
  color: var(--primary);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  padding: 1rem 0;
  display: block;
}

.m-c p {
  line-height: 1.5rem;
  text-align: justify;
  font-size: 0.9rem;
}
/* Method Component Styling Ends Here */

/* FAQ Component Styling Starts Here */
.faq-container {
  max-width: 90%;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 10px;
  border-bottom: 1px solid var(--primary);
}

.faq-question {
  padding: 1rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
  font-weight: bold;
}

.faq-answer {
  padding: 10px;
  color: var(--ternary);
  font-size: 0.9rem;
}

.faq-icon {
  font-size: 2.5rem;
  font-weight: 300;
  transform: rotate(0deg);
  transition: transform 0.2s;
}

.faq-icon.open {
  transform: rotate(225deg);
}
/* FAQ Component Styling Ends Here */

/* Service Component Styling Starts Here */
.s-card {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.s-card:hover {
  background-color: var(--primary);
  border-radius: 3rem;
  color: var(--white);
  cursor: pointer;
  transition: 0.1s ease;
}

.s-card svg {
  color: var(--white);
  padding: 1rem;
  font-size: 1.5rem;
  background-color: var(--primary);
  border-radius: 100%;
}

.s-card span {
  font-size: 0.9rem;
}

/* Service Component Styling Ends Here */

/* About Us Styles Starts Here */
.about-content h1 {
  text-transform: capitalize;
}

.about-content p {
  line-height: 2rem;
  font-size: 1rem;
  text-align: justify;
}

.brand-h {
  height: 70vh;
  background-attachment: fixed;
  border-radius: 1rem;
}

.over-lay {
  background-color: #17495641;
  height: 100%;
  border-radius: 1rem;
}

.over-lay img {
  width: 100%;
}

.about-main h1 {
  font-size: 2rem;
  font-weight: 300;
  text-align: justify;
}

.about-h {
  font-weight: 300;
  color: var(--ternary);
  font-size: 2.5rem;
}

.about-d {
  line-height: 1.9rem;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.about-btn {
  font-size: 3.1rem;
}

.about-btn button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  padding:.9rem 1rem;
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: .7rem;
  font-size: .9rem;
  margin-top: 1rem;
}

.about-btn button:hover {
  cursor: pointer;
}

.dropdown-content {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
}

.dropdown-content a {
  font-weight: 500;
  padding: 0.1rem 0;
  color: var(--ternary);
}

.dropdown-content a:hover {
  color: var(--primary);
}

/* About Us Styles Ends Here */

/* SMM Styles Starts Here */
.smm-content h1 {
  color: var(--primary);
  font-size: 2rem;
  font-weight: 400;
}

.smm-content p {
  font-size: 0.9rem;
  margin: 1rem 0;
}

.smm-main-points h3 {
  font-size: 1rem;
  color: var(--primary);
}

.smm-main-points p {
  font-size: 0.9rem;
  color: var(--ternary);
}
/* SMM Styles Stas Here */

/* Responsive For Mobile Starts Here */

@media (max-width: 767px) {
  .faq-container {
    max-width: 100%;
    margin: 0 auto;
  }

  .about-content p {
    text-align: center;
  }
  .m-c {
    text-align: center;
  }
  .about-us-page {
    text-align: center;
  }
  .about-btn {
    font-size: 2rem;
  }

  .about-h {
    font-size: 2rem;
  }

  .about-btn button {
    width: 100%;
  }

  .smm-content {
    text-align: center;
  }
  .smm-main-points {
    margin: 1rem 0;
    text-align: center;
  }
  .dropdown-content {
    background-color: var(--white);
    width: 89%;
    padding: 3rem 0;
  }
}
/* Service Page Styles Ends Here */

/* Responsive For Mobile Ends Here */

/* Tablet Responsive Starts Here */

@media (min-width: 768px) and (max-width: 1024px) {
 
  .about-content {
    text-align: center;
  }

  .about-content p {
    text-align: center;
  }
  .m-c {
    text-align: center;
  }
  .about-us-page {
    text-align: center;
  }
  .about-btn {
    font-size: 2rem;
  }

  .about-h {
    font-size: 2rem;
  }

  .about-btn button {
    width: 100%;
  }
}

/* Tablet Responsive Ends Here */