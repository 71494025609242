/* Blog Page Styles Starts Here */
.blog-page {
  background: linear-gradient(
    100deg,
    #17495610,
    #17495613,
    #ffffff,
    #ffffff,
    #ffffff
  );
}

.heading-blog{
  color: var(--primary);
  font-size: 2.5rem;
}

.blog-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 25rem;
  border-radius: 1rem;
}
.blog-links{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}

.social{
  display: flex;
  gap: 1rem;
}
.blog-links a{
  background-color: var(--white);
  padding:.8rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
}

.blog-links a:hover{
  background-color: var(--ternary);
  transition: .5s ease;
}

.bookmark{
  font-size: 1.5rem;
  text-transform: uppercase;
}

.blog-links a img{
  width: 1.8rem;  
}
.date-container{
  display: flex;
  justify-content: space-between;
  padding:1rem;
  color: var(--ternary);
  font-size: .9rem;
}

.quill-container h1 {
  color: var(--primary);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
}

.quill-container h2{
  color: #174956bd;
  text-transform: capitalize;
  font-size: 1.4rem;
}
.quill-container h3{
  color: var(--ternary);
  text-transform: capitalize;
  font-size: 1.3rem;
}

.quill-container p {
  color: #555757;
  font-size: 0.9rem;
}
.quill-container img {
 width: 50%;

 border-radius: 1rem;
}

.quill-container li {
  list-style: inherit;
  color: var(--ternary);
}
.quill-container u {
  color: var(--ternary);
}

.quill-container a {
  text-decoration: underline;
}

.quill-container blockquote {
  font-size: 1rem;
  margin:50px auto;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid var(--primary) ;
  line-height:1.6;
  position: relative;
  background:#EDEDED;
}

.quill-container blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:var(--primary);
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

.quill-container blockquote::after{
  content: '';
}

.read-time {
  font-size: 0.9rem;
}

.blog-card-all {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.pagination button {
  background-color: var(--primary);
  border: none;
  padding: 1rem;
  color: var(--white);
  cursor: pointer;
}

.pagination button.active{
  background-color: var(
    --ternary
  );
  color: var(--white); 
}
/* Blog Page Styles Ends Here */
@media (max-width: 767px) {
  .blog-links{
    display: none;
  
  }
  .quill-container img {
    width: 100%;

   }
}