/* Home Section Sytyling Starts here */

.highlight {
  color: var(--primary);
}

.home-img {
  width: 100%;
  height: 80vh;
}
/* Home Section Sytyling Ends here */

/* About Section Styling Starts Here */
.square-paragraph {
  width: 100%;
  padding:0; 
  box-sizing: border-box;
  text-align: justify; 
  margin: 0;
}


.about-container {
  background: linear-gradient(
    300deg,
    #ffffff,
    #17495615,
    #ffffff,
    #ffffff,
    #ffffff
  );
}

/* About Section Styling Ends Here */

/* Client Section Styling Starts Here */

.client-heading {
  font-size: 2rem;
  text-align: center;
}

.client-images-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding-bottom: 4rem;
}

.client-image img {
  width: 8rem;
}

/* Client Section Styling Ends Here */

/* Service Styles Starts Here */
.service-section-container {
  background: linear-gradient(
    100deg,
    #17495610,
    #17495613,
    #ffffff,
    #ffffff,
    #ffffff
  );
}
/* Service Styles Ends Here */

/* Call Section Styles Starts Here */
.bg-image {
  border-radius: 1rem;
  background-image: url("https://images.pexels.com/photos/3184339/pexels-photo-3184339.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-attachment: fixed;
  height: 70vh;
  background-size: cover;
  background-position: left;
}
/* Call Section Styles Ends Here */

/* Contact Section Styles Starts Here */
.contact-container {
  background-image: url("https://images.pexels.com/photos/7688102/pexels-photo-7688102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-position: center;
  background-size: cover;
}
.overlay {
  background-color: #174956b9;
  padding: 5rem 0;
}

.contact-content {
  color: var(--white);
}

.contact-content h1 {
  text-transform: capitalize;
  font-size: 2.5rem;
}

.contact-content p {
  margin-bottom: 1rem;
}

.contact-ico {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-ico img {
  width: 1.2rem;
  margin-right: 1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input {
  padding: 1rem;
  border: none;
  border-radius: 1rem;
}

.contact-form input:focus {
  outline: none;
  scale: 1.05;
  transition: 0.5s ease;
}

.contact-form input::placeholder {
  text-transform: capitalize;
}

#message {
  border-radius: 1rem;
  border: none;
  padding: 1rem;
}

#message:focus {
  outline: none;
  scale: 1.05;
  transition: 0.5s ease;
}

.contact-form button {
  width: 50%;
  margin: 0 auto;
  background-color: #e6e6e6ab;
  color: var(--primary);
  font-weight: 600;
}

/* Contact Section Styles Ends Here */

/* Blog Section Styles Starts Here */
.blog-container {
  background: linear-gradient(
    100deg,
    #17495610,
    #17495613,
    #ffffff,
    #ffffff,
    #ffffff
  );
  padding: 5rem 0;
}

.blog-container h1 {
  text-transform: capitalize;
}

.blog-mobile-slider{
  padding-bottom: 4rem;
}
/* Blog Section Styles Ends Here */
