/* Footer Section Styles Starts Here */
.footer-container {
  background-color: var(--primary);
  color: var(--white);
  padding: 5rem 0 1rem;
}

.footer-container h1 {
  text-transform: capitalize;
}

.footer-container p {
  color: #ffffff9f;
  margin: 1rem 0;
}

.footer-brand {
  width: 50%;
  position: relative;
  bottom: 10%;
  left: -10%;
}
.footer-brand-info {
  position: relative;
  bottom: 15%;
}

.footer-container p span {
  color: var(--white);
  font-weight: 500;
  text-transform: capitalize;
}

.img-container img {
  background-color: var(--white);
  padding: 0.5rem;
  border-radius: 0.8rem;
  margin: 0 0.5rem 0 0;
  width: 2rem;
}

.img-container img:hover {
  scale: 1.1;
  background-color: #00000028;
  transition: 0.5s ease;
  cursor: pointer;
}

.footer-link {
  cursor: pointer;
}

.footer-link:hover {
  color: var(--white);
}
/* Footer Section Styles EndSSs Here */
  @media (max-width: 767px) {

    .footer-container{
      padding: 2rem 0;
    }

  
    .footer-brand {
      width: 70%;
      position: relative;
      bottom: 0%;
      left: 0%;
    }


    .footer-brand-info {
      position: relative;
      bottom: 0px !important;
    }
    
  }