/* Service Card Styles Starts Here  */
.service-bg {
  background-position: center;
  background-size: cover;
  border-radius: 1rem;
}

.gap-slider{
  margin-left: 2rem;
}

.service-card{
  width: 96% !important;
}

.gradient-card {
  background: linear-gradient(120deg, #ffffff81, #ffffff7e, #ffffff6e);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 20rem;
  position: relative;
  cursor: pointer;
  border-radius: 1rem;
  
}

.gradient-card h1 {
  font-size: 2.3rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: var(--primary);
}

.gradient-card span {
  color: var(--secondary);
  text-transform: uppercase;
}

.card-content {
  height: 35%;
  width: 70%;
  transition: transform 0.2s ease;
  padding-bottom: 1.5rem;
}

.card-line {
  width: 10%;
  height: 1rem;
  background-color: var(--primary);
  border: none;
  border-radius: 3rem;
  margin: 1rem 0;
}
.hover-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
  opacity: 0;
  transition: 0.6s ease-in-out all;
}

.hover-content p {
  text-transform: capitalize;
  color: var(--black);
  font-weight: 500;
}
.hover-content ul li {
  text-transform: capitalize;
  color: var(--primary);
}

.gradient-card:hover {
  .card-content {
    transform: translateY(-5%);
  }

  .hover-content {
    max-height: 30rem;
    opacity: 1;
    transition: 1s ease all;
  }
}

.l-arrow{
  background-color: var(--white);
  display: inline;
  padding: 1rem 1.1rem;
  color: var(--primary);
  border-radius: 1rem;
  position: absolute;
  z-index: 1;
  left: -2%;
  bottom: 40%;
  cursor: pointer;
}
.r-arrow{
  background-color: var(--white);
  display: inline;
  padding: 1rem 1.3rem;
  color: var(--primary);
  border-radius: 1rem ;
  position: absolute;
  z-index: 1;
  right:-1%;
  bottom: 40%;
  cursor: pointer;
}
/* Service Card Styles Ends Here  */
