/* Blog Card Styles Starts Here */
.blog-card {
  width: 96% !important;
  margin: 0 auto !important;
}


.overlay-b {
  background-color: #1749563b;
  height: 20rem;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay-b:hover {
  background-color: #174956b7;
  transition: 0.5s ease all;

  .button-align p {
    color: var(--white);
  }

  .read-btn {
    color: var(--white);
    border-bottom: 2px solid var(--white);
  }
}

.back-img {
  height: 100%;
  border-radius: 1rem;
  background-position: center;
  background-size: cover;
}

.overlay-b h1 {
  color: var(--white);
  font-size: 1.5rem;
}

.button-align {
  display: flex;
  justify-content: space-between;
}

.button-align p {
  color: #cccbcb;
  font-size: 0.9rem;
}

.read-btn {
  background-color: transparent;
  border: none;
  color: #cccbcb;
  cursor: pointer;
  border-bottom: 2px solid #cccbcb;
  padding: 0 0 0.5rem 0;
}

.read-btn:hover {
  scale: 1.1;
  transition: 0.5s ease;
}
/* Blog Card Styles Ends Here */
