/* Loader Styles Starts Here */
.loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
}

.shapes {
  width: 44.8px;
  height: 67.19999999999999px;
  color: #174956;
  position: relative;
}

.shapes::before,
.shapes::after {
  content: "";
  position: absolute;
  inset: 0;
  background: currentColor;
  clip-path: polygon(0 0, 100% 0, 100% 67%, 50% 67%, 50% 34%, 0 34%);
  animation: shapes-ukq0pimd 2s infinite;
}

.shapes::after {
  --s: -1, -1;
}

/* Animation For The Loader */

@keyframes shapes-ukq0pimd {
  0%,
  10% {
    transform: scale(var(--s, 1)) translate(0, 0) rotate(0deg);
  }

  33% {
    transform: scale(var(--s, 1)) translate(0, -22.4px) rotate(0deg);
  }

  66% {
    transform: scale(var(--s, 1)) translate(11.2px, -22.4px) rotate(-90deg);
  }

  90%,
  100% {
    transform: scale(var(--s, 1)) translate(11.2px, -11.2px) rotate(-90deg);
  }
}
/* Loader Styles Ends Here */
