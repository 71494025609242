/* project-card-styles Starts Here */

.project-container {
  padding: 1rem 0;
  display: flex;
  transition: transform 0.9s ease-in-out;
  gap: 1rem;
}

/* Styles for individual cards */
.card {
  background-color: #8f8f8f36;
  border-radius: 1rem;
  cursor: pointer;
  width: 96% !important;
  height: 100%;
  margin-right: 1rem;
  transition: transform 1s ease-in-out;
}

.card-bg {
  background-position: center;
  background-size: 100% 100%;
  height: 12rem;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card-content-project {
  padding: 1rem;
}

.card-content-project hr {
  width: 3.5rem;
  height: 0.8rem;
  background-color: var(--primary);
  border-radius: 2rem;
}

.card-content-project span {
  text-transform: uppercase;
  color: var(--secondary);
  font-size: 0.8rem;
  font-weight: 500;
}

.card-content-project p {
  text-transform: capitalize;
  font-size: 1.8rem;
  color: var(--primary);
  font-weight: bold;
}

/* project-card-styles Ends Here */
