.page-head{
    color: var(--primary);
    font-size: 1.5rem;
    padding: 1rem 0;
}

.page-para{
    font-size: .9rem;
    line-height: 1.7rem;
    margin: 0 0 1rem 0;
}

.page-btn{
    margin-top: 3rem;
    padding: 1rem 1.2rem;
    background-color: var(--primary);
    color: var(--white);
    border: none;
    text-transform: capitalize;
    cursor: pointer;
}

.page-btn:hover{
    scale: 1.1;
    transition: .2s ease-in-out;
}

